import { NavBar } from "antd-mobile";
import { useNavigate } from "react-router-dom";


export default function TopNav() {
    const navigate = useNavigate();

    const back = () => {
        navigate('/home')
    }
    return (
        <>
            <NavBar back='Back' onBack={back} />
        </>
    )
}