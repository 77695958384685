import { List, Button, AutoCenter } from "antd-mobile";
import TopbarComponent from "../../components/topbar/TopbarComponent";
import TopNav from "../../components/topNav/TopNav";
import styles from './styles.module.css'
import { useEffect, useState } from "react";
import api from "../../api";
import { useNavigate } from "react-router-dom";

export default function CheckListHome() {

    const [userId, setUserId] = useState();
    const [templates, setTemplates] = useState([])

    const navigate = useNavigate();

    useEffect(() => {
        const userJson = localStorage.getItem('user');
        const user = JSON.parse(userJson);
        setUserId(user.userId)
    }, [])

    useEffect(async () => {
        let res = await api.getTemplates(userId);
        setTemplates(res.data.obj)
    }, [userId])

    return (
        <>
            <TopNav />
            <AutoCenter className={styles.title}>
                System Template
            </AutoCenter>
            <List>
                <List.Item>
                    <Button block color="primary"
                        onClick={
                            async () => {
                                localStorage.setItem("template_id", '0001');
                                navigate("/task")
                            }}
                    > Use Kitchen Template </Button>
                </List.Item>
                <List.Item>
                    <Button block color="primary"
                        onClick={
                            async () => {
                                localStorage.setItem("template_id", '0002');
                                navigate("/task")
                            }}
                    > Use Travel Template </Button>
                </List.Item>
                <List.Item>
                    <Button block shape="outline" onClick={() => { navigate('/createCheckList') }}>Create A Template</Button>
                </List.Item>
            </List>
            <AutoCenter className={styles.title}>
                My Template
            </AutoCenter>
            {
                templates.map(template => {

                    return (
                        <List>
                            <List.Item>
                                <Button block color="warning"
                                    onClick={
                                        async () => {
                                            localStorage.setItem("template_id", template.checkId);
                                            navigate("/task")
                                        }}
                                >{template.title} </Button>
                            </List.Item>
                        </List>
                    )
                })
            }
        </>
    )
}