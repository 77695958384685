import React, { useEffect } from 'react'
import { Input, Button, Toast, Space, Dialog } from 'antd-mobile'
import { EyeInvisibleOutline, EyeOutline } from 'antd-mobile-icons'
import { useState } from 'react'
import styles from './style.module.css'
import { Navigate, useNavigate } from 'react-router-dom'
import logo from '../../assets/photos/instant.png'
import api from '../../api'

export default function Login() {

    const navigate = useNavigate();
    const [visible, setVisible] = useState(false)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [uuid, setUuid] = useState('')
    const [kaptchaText, setKaptchText] = useState('');
    const [kaptcha, setKaptcha] = useState('')


    useEffect(() => {
        getKaptch();
    }, [])

    async function getKaptch() {
        let res = await api.kaptcha();
        let data = res.data;
        setUuid(data.obj.uuid);
        setKaptcha(data.obj.img);
    }

    const refreshKaptch = async () => {
        getKaptch();
    }

    async function submit() {
        if (username === '' || password === '') {
            Toast.show({
                icon: 'fail',
                content: 'Please input the username or password'
            })
            return;
        }
        if (kaptchaText === '') {
            Toast.show({
                icon: 'fail',
                content: 'Please input the kaptcha code'
            })
            return;
        }
        console.log("hello");
        let res = await api.verifyKaptcha({
            uuid: uuid,
            kaptchaText: kaptchaText
        })

        console.log(res.data);

        if (res.data.code != 200) {
            console.log("failed");
            Toast.show({
                icon: 'fail',
                content: 'Wrong Code',
            })
            getKaptch()
            return;
        }

        let res2 = await api.login({
            userName: username,
            password: password
        });
        console.log(res2.data);

        if (res2.data.code == 500210) {
            Toast.show({
                icon: 'fail',
                content: 'Wrong username or password',
            })
            getKaptch()
        }

        if (res2.data.code == 200) {
            let user = res2.data.obj;
            localStorage.setItem('user', JSON.stringify(user))
            setTimeout(() => {
                navigate("/home")
            }, 1500)
            Toast.show({
                icon: 'success',
                content: 'Login successully',
            })

        }


    }

    // async function getData() {
    //     let res = await api.getUser({ username: username, password: password })
    //     console.log(res);
    //     if (res.data.code !== 200) {
    //         Toast.show({
    //             icon: 'fail',
    //             content: 'Wrong username or password'
    //         })
    //         return;
    //     }
    //     const user = res.data.obj
    //     console.log('user', user);
    //     user.password = '';
    //     localStorage.setItem('user', JSON.stringify(user))
    //     localStorage.setItem('iflogin', true)
    //     navigate(`/home?justLogin=true`)
    // }

    // function back() {
    //     navigate('/home')
    // }

    return (
        <div className={styles.login}>

            <div className={styles.brand}>
                <img src={logo} className={styles.logo} />
            </div>

            {/* 用户名密码 */}
            <div className={styles.loginbox}>
                <div className={styles.password}>
                    <Input placeholder='Username or email'
                        onChange={Value => { setUsername(Value) }}
                        style={{
                            '--color': 'white',
                            paddingLeft: '0.8rem',
                        }} />
                </div>
                <div>
                    <div className={styles.password}>
                        <Input
                            onChange={Value => { setPassword(Value) }}
                            className={styles.input}
                            placeholder='Password'
                            type={visible ? 'text' : 'password'}
                            style={{
                                '--color': 'white',
                                padding: '0.8rem'
                            }}
                        />
                        <div className={styles.eye}>
                            {!visible ? (
                                <EyeInvisibleOutline onClick={() => setVisible(true)} />
                            ) : (
                                <EyeOutline onClick={() => setVisible(false)} />
                            )}
                        </div>
                    </div>
                </div>
                <Space direction='horizontal'>
                    <div className={styles.password}>
                        <Input placeholder='Code'
                            onChange={Value => { setKaptchText(Value) }}
                            style={{
                                '--color': 'white',
                                paddingLeft: '0.8rem',
                            }} />
                    </div>
                    <img
                        onClick={getKaptch}
                        src={`data:image/gif;base64,${kaptcha}`}
                        className={styles.kaptcha} />
                </Space>
            </div>

            {/* 登录按钮 */}
            <Button block
                color='secondary'
                size='large'
                shape='rounded'
                onClick={submit}
                className={styles.submitbtn}
            >Log in</Button>

            <Button
                color='secondary'
                fill='outline'
                className={styles.createbtn}
                onClick={() => {
                    navigate('/register')
                }}
                shape='rounded'>

                Create new account
            </Button>
        </div>


    )
};

