import { AutoCenter, List, NavBar, Switch, Space, Button } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import styles from './styles.module.css'
import { useEffect, useState } from "react";
import api from "../../api";


export default function Task() {
    const navigate = useNavigate();
    const [userId, setUserId] = useState('');
    const [templateId, setTemplateId] = useState('');
    const [tasks, setTasks] = useState([])
    const [title, setTitle] = useState('')
    const [currentDateTime, setCurrentDateTime] = useState('')
    const [change, setChange] = useState(true)
    const [taskId, setTaskId] = useState('')

    let arr = []
    const back = () => {
        navigate('/checkListHome')
    }

    useEffect(() => {
        const template_id = localStorage.getItem("template_id");
        console.log("id=>", template_id);
        const userJson = localStorage.getItem('user');
        const user = JSON.parse(userJson);
        setUserId(user.userId)
        setTemplateId(template_id);
        let now = new Date();

        // 获取当前的年份、月份、日期、小时和分钟
        let year = now.getFullYear();
        let month = (now.getMonth() + 1).toString().padStart(2, '0'); // 月份是从0开始的，所以需要加1
        let day = now.getDate().toString().padStart(2, '0');
        let hours = now.getHours().toString().padStart(2, '0');
        let minutes = now.getMinutes().toString().padStart(2, '0');

        // 格式化输出
        let currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
        setCurrentDateTime(currentDateTime);
    }, [])

    useEffect(
        async () => {
            if (templateId != '' && userId != '') {
                let res = await api.getTasks(userId, templateId);
                console.log(res);
                let tasks = res.data.obj.tasks;
                for (let task of tasks) {
                    task.checked = true;
                }
                setTasks(tasks)
                setTitle(res.data.obj.object.title)
                setTaskId(res.data.obj.uuid);
            }

        }, [userId])

    const handleSwitchChange = (index, checked) => {
        const newTasks = [...tasks];
        newTasks[index].checked = checked;
        setTasks(newTasks);
    };

    return (
        <div className={styles.listContainer}>
            <NavBar back='Back' onBack={back} />
            <AutoCenter> {title} </AutoCenter>
            <AutoCenter> {currentDateTime} </AutoCenter>

            <div className={styles.content}>
                {
                    tasks.map((task, index) => {
                        return <div className={styles.box} style={{ backgroundColor: task.checked ? 'red' : 'green' }}>
                            <Space justify="center" block style={{ '--gap': '15%' }}>
                                <div>
                                    {task.name}
                                </div>
                                <Switch defaultChecked onChange={(checked) => handleSwitchChange(index, checked)} />
                            </Space>
                            <AutoCenter className={styles.subtitle}>
                                This One Must Be Kept {task.value === '1' ? 'Off' : 'On'}
                            </AutoCenter>
                        </div>
                    })
                }

            </div>

            <Button color="primary" block
                className={styles.btn}
                onClick={
                    async () => {
                        let unCheckedTask = '1-';
                        tasks.forEach(task => {
                            if (task.checked) {
                                unCheckedTask += " " + task.name;
                            }
                        })
                        let res = await api.submitTask(taskId, unCheckedTask);
                        //navigate()
                    }}>Save</Button>

        </div>
    )
}