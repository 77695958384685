
import styles from './styles.module.css'
import { Button, Form, Input, Stepper, NavBar } from 'antd-mobile'

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
export default function CreateCheckList() {

    const navigate = useNavigate();

    const [name, setName] = useState('')
    const [count, setCount] = useState(0);

    function back() {
        navigate("/home")
    }
    return (
        <>
            <NavBar onBack={back}>Create a checklist</NavBar>
            <div className={styles.container}>
                <div className={styles.box}>
                    <Form layout='horizontal' className={styles.form}>
                        <Form.Item label='Checklist title' name='title'>
                            <Input
                                onChange={(val) => {
                                    setName(val)
                                }}
                                placeholder='Please input the title' clearable />
                        </Form.Item>
                        <Form.Item label='Item count' name='count'>
                            <Stepper
                                defaultValue={1}
                                min={1}
                                max={20}
                                onChange={value => {
                                    setCount(value)
                                }}
                            />
                        </Form.Item>
                        <Button
                            onClick={() => {
                                localStorage.setItem('title', name);
                                localStorage.setItem('count', count);
                                navigate("/createCheckList2")
                            }}
                            color='primary' block style={{
                                marginTop: '1vh'
                            }}>Next</Button>
                    </Form>
                </div>
            </div>
        </>
    )
}