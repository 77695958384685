import { AutoCenter, Avatar, Button, List, SearchBar, ActionSheet, Toast, Dialog, Dropdown, Space, Radio } from 'antd-mobile'
import styles from './style.module.css'
import TopbarComponent from '../../components/topbar/TopbarComponent'
import add from '../../assets/photos/add.png'
import add2 from '../../assets/photos/add2.png'
import add3 from '../../assets/photos/wastage.png'
import { act, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../../api'
import { wait } from '@testing-library/user-event/dist/utils'
export default function Home() {

    const navigate = useNavigate();
    const [username, setUsername] = useState('')
    const [userId, setUserId] = useState('')
    const [alerts, setAlerts] = useState([])
    const [lastLoginDate, setLastLoginDate] = useState('')
    const [text, setText] = useState('')

    const actions = [
        { text: 'Sort by post date', key: 'post' },
        { text: 'Sort by expiration date', key: 'expiration' },
    ]
    const [visible, setVisible] = useState(false)


    function search(val) {
        setText(val);

    }
    useEffect(() => {

        const userJson = localStorage.getItem('user');
        const user = JSON.parse(userJson);
        if (user != null) {
            setUsername(user.userName);
            setUserId(user.userId);
            setLastLoginDate(user.lastLoginDate);
        } else {
            navigate('/login')
        }
    }, [])

    useEffect(() => {
        if (userId != null && userId !== '') {
            fetchAlerts(userId);
        }
    }, [userId]);

    const fetchAlerts = async () => {
        let res = await api.getAlertByUser(userId);
        setAlerts(res.data.obj)
    }

    const fetchAlertsByPostDate = async () => {
        let res = await api.sortByAddDate(userId);
    }

    return (
        <div className={styles.container}>
            {/* Top */}
            <div className={styles.top}>
                <List className={styles.list}>
                    <List.Item
                        prefix={<Avatar src=''></Avatar>}
                        description={`Last login: ${lastLoginDate === '' ? '' : lastLoginDate}`}
                    >
                        {username === '' ? "User" : username}
                    </List.Item>
                </List>
            </div>
            {/* middle  */}
            <div className={styles.middle}>

                <SearchBar
                    placeholder='My Storage'
                    className={styles.search}
                    onChange={(val) => {
                        search(val)
                    }}
                    onSearch={(val) => {
                        localStorage.setItem('search', val);
                        navigate("/searchResult")
                    }}
                />

                <img src={add} className={styles.middleButton1}
                    onClick={() => {
                        {
                            Toast.show({
                                icon: 'loading',
                                duration: 500,
                            })
                        }
                        setTimeout(() => {
                            navigate('/addFood')
                        }, 500)
                    }} />
                <img src={add2} className={styles.middleButton2}
                    onClick={() => {
                        {
                            Toast.show({
                                icon: 'loading',
                                duration: 500,
                            })
                        }
                        setTimeout(() => {
                            navigate('/checkListHome')
                        }, 500)
                    }} />
                <img src={add3}
                    onClick={() => {
                        {
                            Toast.show({
                                icon: 'loading',
                                duration: 500,
                            })
                        }
                        setTimeout(() => {
                            navigate('/addWastage')
                        }, 500)
                    }}
                    className={styles.middleButton3} />
                <div className={styles.foodlist}>

                    <span className={styles.alert}>Alert</span>
                    <Button
                        onClick={
                            () => {
                                setVisible(true)
                            }
                        }
                        className={styles.sortBtn}>Default Sort</Button>
                    <ActionSheet
                        visible={visible}
                        actions={actions}
                        cancelText='close'
                        onClose={() => setVisible(false)}
                        onAction={action => {
                            if (action.key == 'post') {
                                fetchAlertsByPostDate();
                            } else if (action.key == 'expiration') {
                                fetchAlerts();
                            }
                        }}
                    />
                    <List className={styles.itemlist}>
                        {
                            alerts.map(alert => {
                                let backgroundColor;
                                if (alert.alertThreshold === 0) {
                                    backgroundColor = 'red';
                                } else if (alert.alertThreshold === 1) {
                                    backgroundColor = '#FFBD59';
                                } else if (alert.alertThreshold === 2) {
                                    backgroundColor = 'green';
                                } else {
                                    backgroundColor = 'gray';
                                }
                                return <List.Item
                                    onClick={
                                        async () => {
                                            const result = await Dialog.confirm({
                                                content: 'Tick this item',
                                                confirmText: 'Yes',
                                                cancelText: 'Cancel'
                                            })
                                            if (result) {
                                                let res = await api.tickItem(alert.alertId);
                                                Toast.show({
                                                    icon: 'loading',
                                                    duration: 2000,
                                                })
                                                let res2 = await api.getAlertByUser(userId);
                                                setAlerts(res2.data.obj)
                                            }
                                        }
                                    }
                                    className={styles.item}
                                    style={{
                                        backgroundColor: `${backgroundColor}`
                                    }}
                                    Exp description={`Expiration date: ${alert.expireDay}`}
                                    prefix={<div className={styles.prefix}>{alert.leftDay} Days</div>}
                                >
                                    <div className={styles.itemName}>
                                        Name: {alert.itemName}
                                    </div>
                                    <div>
                                        Bought date: {alert.createDay}
                                    </div>
                                    <div>
                                        Location: {alert.storageLocation}
                                    </div>
                                </List.Item>
                            })
                        }
                    </List>
                </div>
            </div>
            {/* bottom */}

            <div>
                <TopbarComponent />
            </div>
        </div>
    )
}