
import { Avatar, Button, List } from "antd-mobile"
import styles from './styles.module.css'
import TopbarComponent from "../../components/topbar/TopbarComponent"
import { useNavigate } from "react-router-dom"
import { NavBar } from "antd-mobile"
import { useEffect, useState } from "react"
export default function Personal() {

    const navigate = useNavigate();
    const [user, setUser] = useState({})
    const back = () => {
        navigate('/home')
    }

    useEffect(() => {
        const userJson = localStorage.getItem('user');
        const user = JSON.parse(userJson);
        setUser(user);
    })

    return (
        <>
            <div>
                <NavBar back='Back' onBack={back}>
                    Personal Information
                </NavBar>
                <div className={styles.top}>
                    <List>
                        <List.Item
                            prefix={<Avatar src=''></Avatar>}
                            description={`User creation time: ${user.createDate}`}
                        >
                            {user.userName}
                        </List.Item>
                        <List.Item description={`${user.email}`} >
                            Email
                        </List.Item>
                        <List.Item description={`${user.houseName}`}>
                            House name
                        </List.Item>
                        <List.Item>
                            <Button onClick={() => {
                                navigate("/inventory")
                            }} block>See my food inventory</Button>
                        </List.Item>
                        <List.Item>
                            <Button onClick={() => {
                                navigate("/wastage")
                            }} block>See my wastage</Button>
                        </List.Item>
                        <List.Item>
                            <Button block
                                onClick={() => {
                                    navigate("/checklistHistory")
                                }}
                            >See my checklist history</Button>
                        </List.Item>
                        <List.Item>
                            <Button color='danger' block>Log out</Button>
                        </List.Item>
                    </List>
                </div>
            </div>
            <div >
                <TopbarComponent />
            </div>
        </>
    )
}