import React from 'react'
import ReactDom from 'react-dom'
import App from './App'
import './assets/base.css'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd-mobile'
import enUS from 'antd-mobile/es/locales/en-US'


ReactDom.render(
    <BrowserRouter>
        <ConfigProvider locale={enUS}>
            <App />
        </ConfigProvider>
    </BrowserRouter>,
    document.getElementById('root')
) 