import TopNav from "../../components/topNav/TopNav";
import Dropdown from "antd-mobile/es/components/dropdown/dropdown";
import { Radio, Space, List, Dialog, Toast, Empty } from "antd-mobile";
import api from "../../api";
import styles from './styles.module.css'
import { useEffect, useState } from "react";
import vegetable from '../../assets/food-icons/vegetables.png'
import meat from '../../assets/food-icons/meat.png'
import fruit from '../../assets/food-icons/fruit.png'
import grain from '../../assets/food-icons/grain.png'
import milk from '../../assets/food-icons/milk.png'
import oil from '../../assets/food-icons/oil.png'
import others from '../../assets/food-icons/others.png'
import eggs from '../../assets/food-icons/eggs.png'
import drinks from '../../assets/food-icons/drinks.png'
import { useNavigate } from "react-router-dom";
export default function Inventory() {

  const [foodList, setFoodList] = useState();
  const [userId, setUserId] = useState();
  const [count, setCount] = useState();
  const navigate = useNavigate();
  useEffect(() => {

    const userJson = localStorage.getItem('user');
    const user = JSON.parse(userJson);
    if (user != null) {
      console.log(user.userId);
      setUserId(user.userId)
    } else {
      navigate('/login')
    }
  }, [])

  useEffect(async () => {
    let res = await api.getFoodList(userId);
    setFoodList(res.data.obj)
  }, [userId])

  useEffect(async () => {
    let res = await api.currentInventoryAmount(userId);
    setCount(res.data.obj);
  })

  return (
    <div className={styles.container}>
      <div>
        <TopNav />
      </div>

      {/* dropdown */}
      <div>
        <Dropdown>
          <Dropdown.Item key='sorter' title='Select'>
            <div style={{ padding: 12 }}>
              <Radio.Group defaultValue='default'>
                <Space direction='vertical' block>
                  <Radio block value='default'
                    onClick={async () => {
                      let res = await api.getFoodList(userId);
                      setFoodList(res.data.obj)
                    }}>
                    Sort by post date.
                  </Radio>
                  <Radio block value='top-rated'
                    onClick={async () => {
                      let res = await api.getFoodListByExpirationDate(userId);
                      setFoodList(res.data.obj)
                    }}>
                    Sort by expiration date.
                  </Radio>
                </Space>
              </Radio.Group>
            </div>
          </Dropdown.Item>
        </Dropdown>
      </div>
      {/* main */}
      <div>
        <div>
          <List className={styles.itemlist}>
            {
              foodList && foodList.length > 0 ? (
                foodList.map(food => {
                  let iconSrc;
                  if (food.foodCategory === 'vegetables') {
                    iconSrc = vegetable;
                  } else if (food.foodCategory === 'meat') {
                    iconSrc = meat;
                  } else if (food.foodCategory === 'egg') {
                    iconSrc = eggs;
                  } else if (food.foodCategory === 'grain') {
                    iconSrc = grain;
                  } else if (food.foodCategory === 'fruit') {
                    iconSrc = fruit;
                  } else if (food.foodCategory === "dairy") {
                    iconSrc = milk;
                  } else if (food.foodCategory === 'oil') {
                    iconSrc = oil;
                  } else if (food.foodCategory === 'drinks') {
                    iconSrc = drinks;
                  } else {
                    iconSrc = others
                  }
                  return (

                    <List.Item
                      onClick={
                        async () => {
                          const result = await Dialog.confirm({
                            content: 'Tick this item',
                            confirmText: 'Yes',
                            cancelText: 'Cancel'
                          })
                          if (result) {
                            console.log('food id =>' + food.foodId);
                            let res = await api.tickInventoryItem(food.foodId);
                            Toast.show({
                              icon: 'loading',
                              duration: 2000,
                            })
                            let res2 = await api.getFoodList(userId);
                            setFoodList(res2.data.obj)
                            let res3 = await api.currentInventoryAmount(userId);
                            setCount(res.data.obj);
                          }
                        }
                      }
                      className={styles.item}
                      style={{
                        // backgroundColor: `${backgroundColor}`
                      }}
                      Exp description={`${food.label == 1 ? 'Use by' : 'Best before'} date: ${food.expireDate}`}
                      prefix={<img src={iconSrc} style={{
                        width: '1rem',
                        marginLeft: '0.3rem',
                        marginRight: '0.5rem'
                      }} />}
                    >
                      <div className={styles.itemName}>
                        Name: {food.foodName}
                      </div>
                      <div>
                        Bought date: {food.purchaseDate}
                      </div>
                      <div>
                        Location: {food.storageLocation}
                      </div>
                    </List.Item>
                  )
                })
              ) : <div>
                <Empty
                  style={{ padding: '64px 0' }}
                  imageStyle={{ width: 128 }}
                  description='No foods yet'
                />
              </div>
            }

          </List>
          <div style={{
            float: 'right',
            marginRight: '1rem',
            fontSize: '2vh',
            lineHeight: '2vh',
            marginTop: '2vh',
            fontWeight: 'bold',
            color: '#01AC71'
          }}>
            Count: {count}
          </div>
        </div>

      </div>
    </div>
  )
}