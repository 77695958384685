
import Login from '../pages/login/Login'
import Home from '../pages/home/Home'
import Register from '../pages/register/Register'
import AddFood from '../pages/addFood/AddFood'
import ResultPage from '../pages/resultPage/ResultPage'
import AddWastage from '../pages/addWastage/AddWastage'
import Inventory from '../pages/inventory/Inventory'
import Personal from '../pages/personal/Personal'
import Wastage from '../pages/wastage/Wastage'
import Statistics from '../pages/statistics/Statistics'
import CheckListHome from '../pages/checkListHome/CheckListHome'
import CreateCheckList from '../pages/createCheckList/CreateCheckList'
import CreateCheckList2 from '../pages/createCheckList2/CreateCheckList2'
import SearchResult from '../pages/searchResult/SearchResult'
import Task from '../pages/Task/Task'
import ChecklistHistory from '../pages/checklistHistory/ChecklistHistory'
import StatisticsForChecklist from '../pages/statisticsForChecklist/StatisticsForChecklist'
import { ErrorBlock } from 'antd-mobile'
import ErrorPage from '../pages/errorPage/ErrorPage'

const routes = [
    {
        path: '/home',
        element: <Home />,
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/Register',
        element: <Register />
    },
    {
        path: '/addFood',
        element: <AddFood />
    },
    {
        path: '/resultPage',
        element: <ResultPage />
    },
    {
        path: '/addWastage',
        element: <AddWastage />
    },
    {
        path: '/inventory',
        element: <Inventory />
    },
    {
        path: '/wastage',
        element: <Wastage />
    },
    {
        path: '/me',
        element: <Personal />
    },
    {
        path: '/statistics',
        element: <Statistics />
    },
    {
        path: '/checkListHome',
        element: <CheckListHome />
    },
    {
        path: '/createCheckList',
        element: <CreateCheckList />
    },
    {
        path: '/createCheckList2',
        element: <CreateCheckList2 />
    },
    {
        path: '/searchResult',
        element: <SearchResult />
    },
    {
        path: '/task',
        element: <Task />
    },
    {
        path: '/checklistHistory',
        element: <ChecklistHistory />
    },
    {
        path: '/statisticsForChecklist',
        element: <StatisticsForChecklist />
    },
    {
        path: '/error',
        element: <ErrorPage />
    }, {
        path: '/',
        element: <Home />
    }
]

export default routes