// export const host = process.env.REACT_APP_PRODUCTION_URL;

// const base = {
//     host: host,
//     kaptcha: 'api/user/kaptcha',
//     verifyKaptcha: 'api/user/verifyKaptcha',
//     login: 'api/user/login',
//     register: 'api/user/register',
//     addFood: 'api/food/addFood',
//     getAlertByUser: 'api/item-alert/getAlerts',
//     sortByAddDate: 'api/item-alert/sortByAddDate',
//     tickItem: 'api/item-alert/tickItem',
//     tickInventoryItem: 'api/item-alert/tickInventoryItem',
//     addWastage: 'api/food-wastage/addWastage',
//     getFoodList: 'api/food/getFoodList',
//     getFoodListByExpirationDate: 'api/food/getFoodListByExpirationDate',
//     getThreeMonthsWastagePrice: 'api/food-wastage/getThreeMonthsWastagePrice',

//     addCheckList: 'api/checklist/addCheckList',
//     getWastageList: 'api/food-wastage/getWastageList',
//     currentInventoryAmount: 'api/food/currentInventoryAmount',
//     search: 'api/food/search',
//     getTemplates: 'api/checklist/getTemplates',
//     getTasks: 'api/checklist/getTasks',
//     submitTask: 'api/checklist/submitTask',
//     getTasksHistory: 'api/checklist/getTasksHistory',
//     getStatistics: 'api/checklist/getStatistics',
//     getFollowing: 'api/user/getFollowing',
//     ifFollow: 'api/user/ifFollow',
//     getUserById: 'api/user/getUserById',
//     verifycode: 'api/user/verifycode',
//     uploadPhoto: 'api/items/upload',
//     saveItem: 'api/items/save',
//     getByCategory: 'api/items/getByCategory',
//     getItemByItemId: 'api/items/getItemByItemId',

// }

// export default base

export const host = process.env.REACT_APP_PRODUCTION_URL;

const base = {
    host: host,
    kaptcha: 'user/kaptcha',
    verifyKaptcha: 'user/verifyKaptcha',
    login: 'user/login',
    register: 'user/register',
    addFood: 'food/addFood',
    getAlertByUser: 'item-alert/getAlerts',
    sortByAddDate: 'item-alert/sortByAddDate',
    tickItem: 'item-alert/tickItem',
    tickInventoryItem: 'item-alert/tickInventoryItem',
    addWastage: 'food-wastage/addWastage',
    getFoodList: 'food/getFoodList',
    getFoodListByExpirationDate: 'food/getFoodListByExpirationDate',
    getThreeMonthsWastagePrice: 'food-wastage/getThreeMonthsWastagePrice',

    addCheckList: 'checklist/addCheckList',
    getWastageList: 'food-wastage/getWastageList',
    currentInventoryAmount: 'food/currentInventoryAmount',
    search: 'food/search',
    getTemplates: 'checklist/getTemplates',
    getTasks: 'checklist/getTasks',
    submitTask: 'checklist/submitTask',
    getTasksHistory: 'checklist/getTasksHistory',
    getStatistics: 'checklist/getStatistics',
    getFollowing: 'user/getFollowing',
    ifFollow: 'user/ifFollow',
    getUserById: 'user/getUserById',
    verifycode: 'user/verifycode',
    uploadPhoto: 'items/upload',
    saveItem: 'items/save',
    getByCategory: 'items/getByCategory',
    getItemByItemId: 'items/getItemByItemId',

}

export default base