
import { useNavigate } from "react-router-dom"
import { List, NavBar, SearchBar, Empty, Button, Modal } from "antd-mobile";
import { useEffect, useState } from "react";
import api from "../../api";
import styles from './styles.module.css'
import Task from "../Task/Task";
import { CheckCircleOutline } from "antd-mobile-icons";
export default function () {

    const navigate = useNavigate();
    const [userId, setUserId] = useState('')
    const [tasks, setTasks] = useState([])
    const [content, setContent] = useState('null');
    const [visible, setVisible] = useState(false)
    const [unfinished, setUnfinished] = useState();

    const back = () => {
        navigate('/home')
    }

    useEffect(() => {
        const userJson = localStorage.getItem('user');
        const user = JSON.parse(userJson);
        setUserId(user.userId);
    }, [])

    useEffect(
        async () => {
            if (userId != '' && content != '') {
                let res = await api.getTasksHistory(userId, content);
                setTasks(res.data.obj)
                console.log(res.data.obj);
            }
        }, [userId])

    return (
        <>
            <NavBar back='Back' onBack={back}>
                Checklist History
            </NavBar>
            <div className={styles.container} >
                <SearchBar
                    placeholder="You can search date (yyyy-MM-dd) or checklist title"
                    onChange={
                        async (val) => {
                            if (val != '' && val != null) {
                                console.log('ok');
                                let res = await api.getTasksHistory(userId, val);
                                console.log(res);
                                setTasks(res.data.obj)
                            }

                        }
                    } />
                <List>
                    {
                        console.log("length => ", tasks.length)
                    }
                    {

                        tasks && tasks.length > 0 ? (
                            tasks.map((task, index) => {
                                let unfinishedParam = '';
                                if (task.ifFinished == 0) {
                                    if (task.unfinishedTask == '' || (task.unfinishedTask === null)) {
                                        unfinishedParam = 'All tasks are not finished.'
                                    } else {
                                        unfinishedParam = task.unfinishedTask;
                                    }

                                }
                                return <List.Item
                                    style={{
                                        color: task.ifFinished == 1 ? 'green' : 'red',
                                        fontSize: '2vh'
                                    }}
                                    key={index}>
                                    {task.createTime}
                                    &nbsp;
                                    {task.title}
                                    &nbsp;
                                    {task.ifFinished == 1 ?
                                        <span>
                                            <CheckCircleOutline />
                                        </span> :
                                        <span>
                                            <Button color="danger" size="mini"
                                                onClick={() => {
                                                    setUnfinished(unfinishedParam);
                                                    console.log('unfinished:' + unfinished);
                                                    setVisible(true)
                                                }}

                                            >Details</Button>
                                            <Modal
                                                visible={visible}
                                                title='Unfinished tasks'
                                                content={unfinished}
                                                closeOnAction
                                                onClose={() => {
                                                    setVisible(false)
                                                }}
                                                actions={[
                                                    {
                                                        key: 'confirm',
                                                        text: 'OK',
                                                    },
                                                ]}
                                            />
                                        </span>
                                    }
                                </List.Item>
                            })
                        ) : <div>
                            <Empty
                                style={{ padding: '64px 0' }}
                                imageStyle={{ width: 128 }}
                                description='No checklist history yet'
                            />
                        </div>
                    }
                </List >
            </div >

        </>
    )
}