import React, { useState, useEffect } from 'react';
import { Badge, TabBar } from 'antd-mobile';
import {
    AppOutline,
    UnorderedListOutline,
    PieOutline,
    UserOutline
} from 'antd-mobile-icons';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import api from '../../api';

export default () => {
    const navigate = useNavigate();
    const [userId, setUserId] = useState('')
    const [alertsNum, setAlertsNum] = useState(0);
    useEffect(async () => {
        const userJson = localStorage.getItem('user');
        const user = JSON.parse(userJson);
        setUserId(user.userId);
    }, [])

    useEffect(
        async () => {
            if (userId != '') {
                let res = await api.getAlertByUser(userId);
                const obj = res.data.obj;
                let count = 0;
                for (const item of obj) {
                    if (item.alertThreshold == 0 || item.alertThreshold == 1) {
                        count += 1;
                    }
                }
                setAlertsNum(count);
            }
        }, [userId])
    const tabs = [
        {
            key: '/home',
            title: 'Home',
            icon: <AppOutline />,
            badge: alertsNum == 0 ? null : alertsNum
        },
        {
            key: '/inventory',
            title: 'Inventory',
            icon: <UnorderedListOutline />,
        },
        {
            key: '/statistics',
            title: 'Statistics',
            icon: <PieOutline />
        },
        {
            key: '/me',
            title: 'Me',
            icon: <UserOutline />,
        },
    ];

    const [activeKey, setActiveKey] = useState('');


    // onChange 函数 - 改变高亮
    function changeTab(key) {
        console.log('key', key);

    }

    return (
        <div className={styles.layout}>
            <TabBar onChange={(key) => {
                navigate(key);
                setActiveKey(key);
            }}>
                {tabs.map(item => (
                    <TabBar.Item
                        key={item.key}
                        icon={item.icon}
                        title={item.title}
                        badge={item.badge}
                    />
                ))}
            </TabBar>
        </div>
    );
};