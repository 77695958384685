import axios from "axios";
import base from './base'

import { stringify } from "qs";

const client = axios.create({
    // 使用stringify作为参数序列化器
    paramsSerializer: stringify
})

const api = {
    kaptcha() {
        return axios.get(base.kaptcha)
    },
    verifyKaptcha(kaptchaVo) {
        return axios.post(base.verifyKaptcha, kaptchaVo)
    },
    // 获取用户接口
    login(params) {
        return axios.post(base.login, params)
    },
    //校验验证码
    verifycode(params) {
        return axios.get(base.verifycode, { params })
    },
    // Register
    register(params) {
        return axios.post(base.register, params)
    },
    // add food item
    addFood(params) {
        return axios.post(base.addFood, params)
    },
    getAlertByUser(params) {
        return axios.get(`${base.getAlertByUser}/${params}`)
    },
    sortByAddDate(params) {
        return axios.get(`${base.sortByAddDate}/${params}`)
    },
    tickItem(params) {
        return axios.get(`${base.tickItem}/${params}`)
    },
    tickInventoryItem(params) {
        return axios.get(`${base.tickInventoryItem}/${params}`)
    },
    addWastage(params) {
        return axios.post(base.addWastage, params)
    },
    getWastageList(param1, param2) {
        return axios.get(`${base.getWastageList}/${param1}/${param2}`);
    },
    getFoodList(params) {
        return axios.get(`${base.getFoodList}/${params}`)
    },
    getFoodListByExpirationDate(params) {
        return axios.get(`${base.getFoodListByExpirationDate}/${params}`)
    },
    currentInventoryAmount(params) {
        return axios.get(`${base.currentInventoryAmount}/${params}`)
    },
    getThreeMonthsWastagePrice(params) {
        return axios.get(`${base.getThreeMonthsWastagePrice}/${params}`)
    },
    addCheckList(params) {
        return axios.post(base.addCheckList, params);
    },
    search(params1, params2) {
        return axios.get(`${base.search}/${params1}/${params2}`)
    },
    getTemplates(params) {
        return axios.get(`${base.getTemplates}/${params}`)
    },
    getTasks(params1, params2) {
        return axios.get(`${base.getTasks}/${params1}/${params2}`)
    },
    submitTask(params1, params2) {
        return axios.get(`${base.submitTask}/${params1}/${params2}`)
    },
    getTasksHistory(params1, params2) {
        return axios.get(`${base.getTasksHistory}/${params1}/${params2}`)
    },
    getStatistics(params) {
        return axios.get(`${base.getStatistics}/${params}`)
    },
    uploadPhoto(params) {
        return axios.post(base.uploadPhoto, params, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    saveItem(data) {
        return client.post(base.saveItem, data, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
    },
    getByCategory(params) {
        return axios.get(base.getByCategory, { params })
    },
    getItemByItemId(params) {
        return axios.get(base.getItemByItemId, { params })
    },
    followUser(params) {
        return axios.get(base.followUser, { params })
    },
    getFollowers(params) {
        return axios.get(base.getFollowers, { params })
    },
    getFollowing(params) {
        return axios.get(base.getFollowing, { params })
    },
    getUserById(params) {
        return axios.get(base.getUserById, { params })
    },
    ifFollow(params) {
        return axios.get(base.ifFollow, { params })
    }
}

export default api