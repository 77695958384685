import TopNav from "../../components/topNav/TopNav";
import Dropdown from "antd-mobile/es/components/dropdown/dropdown";
import { Radio, Space, List, Dialog, Toast, Empty, CalendarPicker, Button } from "antd-mobile";
import api from "../../api";
import styles from './styles.module.css'
import { useEffect, useState } from "react";
import vegetable from '../../assets/food-icons/vegetables.png'
import meat from '../../assets/food-icons/meat.png'
import fruit from '../../assets/food-icons/fruit.png'
import grain from '../../assets/food-icons/grain.png'
import milk from '../../assets/food-icons/milk.png'
import oil from '../../assets/food-icons/oil.png'
import others from '../../assets/food-icons/others.png'
import eggs from '../../assets/food-icons/eggs.png'
import drinks from '../../assets/food-icons/drinks.png'
import { CalendarOutline } from "antd-mobile-icons";
export default function Wastage() {

  const [foodList, setFoodList] = useState();
  const [userId, setUserId] = useState();
  const [visible1, setVisible1] = useState();
  const [val, setVal] = useState();

  useEffect(() => {
    const userJson = localStorage.getItem('user');
    const user = JSON.parse(userJson);
    console.log(user.userId);
    setUserId(user.userId)

  }, [])

  useEffect(async () => {
    console.log("asda =>" + userId);
    let res = await api.getWastageList(userId);
    setFoodList(res.data.obj)
  }, [userId])




  return (
    <div className={styles.container}>
      <div>
        <TopNav />
      </div>

      {/* dropdown */}
      <div>
        <List.Item
          onClick={() => {
            setVisible1(true)
          }}
          style={{
            marginLeft: '1rem',
            fontSize: '0.3rem'
          }}
        >
          <CalendarOutline />  Please tap here to select a date
          <CalendarPicker
            selectionMode='range'
            visible={visible1}
            onClose={() => setVisible1(false)}
            onMaskClick={() => setVisible1(false)}
            onChange={async (val) => {
              const date = new Date(val);
              const year = date.getFullYear();
              const month = String(date.getMonth() + 1).padStart(2, '0');
              const day = String(date.getDate()).padStart(2, '0');
              const finalDate = `${year}-${month}-${day}`;
              console.log(finalDate);
              let res = await api.getWastageList(userId, finalDate);
              setFoodList(res.data.obj)
              setVal(finalDate);
            }}
          />
        </List.Item>
      </div>
      {/* main */}
      <div>
        <div>
          <Button
            style={{
              backgroundColor: '#01AC71',
              color: 'white'
            }}
            block onClick={
              async () => {
                let res = await api.getWastageList(userId);
                setFoodList(res.data.obj)
              }
            }>Refresh</Button>
          <List className={styles.itemlist}>
            {
              foodList && foodList.length > 0 ? (
                foodList.map(food => {
                  let iconSrc;
                  if (food.foodCategory === 'vegetables') {
                    iconSrc = vegetable;
                  } else if (food.foodCategory === 'meat') {
                    iconSrc = meat;
                  } else if (food.foodCategory === 'egg') {
                    iconSrc = eggs;
                  } else if (food.foodCategory === 'grain') {
                    iconSrc = grain;
                  } else if (food.foodCategory === 'fruit') {
                    iconSrc = fruit;
                  } else if (food.foodCategory === "dairy") {
                    iconSrc = milk;
                  } else if (food.foodCategory === 'oil') {
                    iconSrc = oil;
                  } else if (food.foodCategory === 'drinks') {
                    iconSrc = drinks;
                  } else {
                    iconSrc = others
                  }
                  return (

                    <List.Item
                      className={styles.item}
                      style={{
                        // backgroundColor: `${backgroundColor}`
                      }}
                      Exp description={`Expiration date: ${food.expireDate}`}
                      prefix={<img src={iconSrc} style={{
                        width: '1rem',
                        marginLeft: '0.3rem',
                        marginRight: '0.5rem'
                      }} />}
                    >
                      <div className={styles.itemName}>
                        Name: {food.foodName}
                      </div>
                      <div>
                        Quantity: {food.quantity}
                      </div>
                      <div>
                        Weight: {food.weight} {food.unit}
                      </div>
                      <div>
                        Price: ￡{food.price}
                      </div>
                    </List.Item>
                  )
                })
              ) : <div>
                <Empty
                  style={{ padding: '64px 0' }}
                  imageStyle={{ width: 128 }}
                  description='No foods yet'
                />
              </div>
            }
          </List>
        </div>

      </div>
    </div>
  )
}