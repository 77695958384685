import { Button, CalendarPicker, List, Input, Form, Toast, Selector, Popover } from 'antd-mobile'
import TopNav from '../../components/topNav/TopNav'
import { useEffect, useState } from 'react'
import React, { Component } from 'react'
import styles from './styles.module.css'
import { CapsuleTab, CapsuleTabs } from 'antd-mobile/es/components/capsule-tabs/capsule-tabs'
import api from '../../api'
import { useNavigate } from 'react-router-dom'
import { QuestionCircleOutline } from 'antd-mobile-icons'

export default function AddFood() {

    const navigate = useNavigate();
    const [visibleDate, setVisibleDate] = useState(false)
    const [visibleDate2, setVisibleDate2] = useState(false)
    const [userId, setUserId] = useState('')
    const [foodCategory, setFoodCategory] = useState('')
    const [name, setName] = useState('')
    const [brand, setBrand] = useState('')
    const [weight, setWeight] = useState('')
    const [unit, setUnit] = useState('')
    const [price, setPrice] = useState('')
    const [quantity, setQuantity] = useState('')
    const [purchaseDate, setPurchaseDate] = useState('2024-06-02')
    const [expiredate, setExpireDate] = useState('2024-06-02')
    const [storageLocation, setStorageLocation] = useState('')
    const [label, setLabel] = useState()

    const singleDate = new Date('2024-06-02')

    useEffect(() => {
        const userJson = localStorage.getItem('user');
        const user = JSON.parse(userJson);
        if (user == null || user == '') {
            navigate("/login")
        }
    }, [])

    const submit = async () => {

        const userJson = localStorage.getItem('user');
        const user = JSON.parse(userJson);
        console.log(user.userId);
        if (user != null) {
            setUserId(user.userId)
        }

        if (name === '' || price === '' || unit === '' || storageLocation === ''
            || weight === '' || quantity === '' || brand === '' || purchaseDate === ''
            || foodCategory === '' || label == '') {
            Toast.show({
                icon: 'fail',
                content: 'Please fill all the blanks',
            })
            return;
        }

        if (Number.isInteger(Number(quantity)) == false) {
            Toast.show({
                icon: 'fail',
                content: 'Quantity must be number',
            })
            return;
        }

        if (!isDouble(weight)) {
            Toast.show({
                icon: 'fail',
                content: 'Weight must be number',
            })
            return;
        }

        if (!isDouble(price)) {
            Toast.show({
                icon: 'fail',
                content: 'price must be number',
            })
            return;
        }
        console.log('label =>' + label);
        let res = await api.addFood({
            foodName: name,
            brand: brand,
            quantity: quantity,
            weight: weight,
            unit: unit,
            price: price,
            foodCategory: foodCategory,
            purchaseDate: purchaseDate,
            expireDate: expiredate,
            userId: user.userId,
            storageLocation: storageLocation,
            label: label
        });
        if (res.data.code == 200) {
            navigate("/resultPage")
        }
    }

    const isDouble = (value) => {
        return !isNaN(value) && parseFloat(value) === Number(value);
    }

    const options = [
        {
            label: 'Use by',
            value: '1',
        },
        {
            label: 'Best before',
            value: '2',
        }
    ]
    return (
        <div className={styles.container}>
            <TopNav />
            <div className={styles.title}>
                <span>
                    Add your food <Button onClick={() => { submit() }} shape='rounded' color='primary' className={styles.btn}>Submit</Button>
                </span>
            </div>
            <div >
                <span className={styles.subtitle}>Select food type</span>
                <CapsuleTabs className={styles.tab} defaultActiveKey='Vegetables' onChange={(key) => { setFoodCategory(key) }}>
                    <CapsuleTabs.Tab title='Vegetables' key='vegetables' className={styles.tab}>
                        Type: Vegetables
                    </CapsuleTabs.Tab>
                    <CapsuleTabs.Tab title='Fruit' key='fruit' className={styles.tab}>
                        Type: Fruit
                    </CapsuleTabs.Tab>
                    <CapsuleTabs.Tab title='Meat' key='meat'>
                        Type:  Meat
                    </CapsuleTabs.Tab>
                    <CapsuleTabs.Tab title='Diary' key='dairy'>
                        Type: Diary
                    </CapsuleTabs.Tab>
                    <CapsuleTabs.Tab title='Oil & Seasoning' key='oil'>
                        Type: Oil & Seasoning
                    </CapsuleTabs.Tab>
                    <CapsuleTabs.Tab title='Drinks' key='drinks'>
                        Type: Drinks
                    </CapsuleTabs.Tab>
                    <CapsuleTabs.Tab title='Eggs' key='eggs'>
                        Type: Eggs
                    </CapsuleTabs.Tab>
                    <CapsuleTabs.Tab title='Grain' key='grain'>
                        Type: Grain
                    </CapsuleTabs.Tab>
                    <CapsuleTabs.Tab title='Other' key='other'>
                        Type: Other
                    </CapsuleTabs.Tab>
                </CapsuleTabs>
            </div>

            <div>
                <div className={styles.inlineContainer}>
                    <Selector
                        className={styles.selector}
                        defaultValue={['1']}
                        options={options}
                        onChange={(arr, extend) => {
                            setLabel(arr[0])
                            console.log(label);
                        }}
                    />
                    <Popover
                        content=' ‘Best before’ date pertains to the quality of the food, whereas the ‘Use by’ date pertains to its safety'
                        trigger='click'
                        placement='top'
                    >
                        <Button color='primary' size='mini'>
                            <QuestionCircleOutline
                                fontSize={20} />
                        </Button>

                    </Popover>

                </div>
                <Form layout='vertical' className={styles.form}>
                    <Form.Item label='Food Name' name='foodName'>
                        <Input placeholder='Please input food name' clearable
                            onChange={(val) => { setName(val) }} />
                    </Form.Item>
                    <Form.Item label='Food Brand' name='brand'>
                        <Input placeholder='Please input food brand'
                            onChange={(val) => { setBrand(val) }} />
                    </Form.Item>
                    <Form.Item label='Quantity' name='quantity'>
                        <Input placeholder='Please input quantity (number)'
                            onChange={(val) => { setQuantity(val) }} />
                    </Form.Item>
                    <Form.Item label='Weight' name='weight'>
                        <Input placeholder='Please input weight (number)'
                            onChange={(val) => { setWeight(val) }} />
                    </Form.Item>
                    <Form.Item label='Unit' name='unit'>
                        <Input placeholder='Please input unit (e.g. g / Kg)'
                            onChange={(val) => { setUnit(val) }} />
                    </Form.Item>
                    <Form.Item label='Total Price' name='price'>
                        <Input placeholder='Please input price (unit: ￡ / ＄ / ￥ / €)'
                            onChange={(val) => { setPrice(val) }} />
                    </Form.Item>
                    <Form.Item label='Storage Location' name='location'>
                        <Input placeholder='Please input price'
                            onChange={(val) => { setStorageLocation(val) }} />
                    </Form.Item>
                </Form>
            </div>
            <div className={styles.datepick}>
                <List>
                    <List.Item
                        onClick={() => {
                            setVisibleDate(true)
                        }}
                    >
                        Select purchase date
                        <CalendarPicker
                            visible={visibleDate}
                            selectionMode='single'
                            defaultValue={singleDate}
                            onClose={() => setVisibleDate(false)}
                            onMaskClick={() => setVisibleDate(false)}
                            onChange={(val) => { setPurchaseDate(val) }}
                        />
                    </List.Item>
                </List>
            </div>

            <div className={styles.datepick}>
                <List>
                    <List.Item
                        onClick={() => {
                            setVisibleDate2(true)
                        }}
                    >
                        Select expiration date
                        <CalendarPicker
                            visible={visibleDate2}
                            selectionMode='single'
                            defaultValue={singleDate}
                            onClose={() => setVisibleDate2(false)}
                            onMaskClick={() => setVisibleDate2(false)}
                            onChange={(val) => { setExpireDate(val) }}
                        />
                    </List.Item>
                </List>
            </div>
            <div>

            </div>
        </div>
    )
}