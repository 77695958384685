import { NavBar, List, Input, Checkbox, Card, Space, Radio, Button, Toast } from "antd-mobile";
import { useEffect, useState } from "react"
import styles from './styles.module.css'
import { useNavigate } from "react-router-dom";
import api from "../../api";


export default function CreateCheckList2() {

    const [title, setTitle] = useState('');
    const [count, setCount] = useState(5)
    const [key, setKey] = useState('')
    const [value, setValue] = useState('')
    const navigate = useNavigate();
    const [keyList, setKeyList] = useState([]);
    const [valueList, setValueList] = useState([]);
    let map = new Map();
    useEffect(() => {
        let title = localStorage.getItem('title');
        let count = localStorage.getItem('count');
        if (title == '' || count == '') {
            navigate("/createCheckList");
        }
        // 设置标题和计数状态
        if (title) setTitle(title);
        if (count) setCount(parseInt(count));
    })


    function back() {
        navigate("/createCheckList")
    }

    function confirmKey(index, value) {
        keyList[index] = value;
        console.log(keyList);
    }

    function confirmValue(index, value) {
        valueList[index] = value;
        console.log(valueList);
    }

    async function submit() {
        for (let i = 0; i < keyList.length; i++) {
            if (keyList[i] === '') {
                Toast.show({
                    icon: 'error',
                    content: 'Please fill all the blanks',
                })
            }
        }
        const mapObj = {};
        for (let i = 0; i < keyList.length; i++) {
            mapObj[keyList[i]] = valueList[i];
        }
        const userJson = localStorage.getItem('user');
        const user = JSON.parse(userJson);
        if (user === null || user === '') {
            navigate('/login')
        }
        mapObj['userId'] = user.userId;
        mapObj['count'] = count;
        mapObj['title'] = title;
        console.log(mapObj);
        try {
            let res = await api.addCheckList(mapObj);
            console.log(res.data); // Handle response as needed'
            if (res.data.code == 200) {
                Toast.show({
                    icon: 'success',
                    content: 'Created Successfully',
                })
                navigate("/home")
            } else {
                Toast.show({
                    icon: 'error',
                    content: 'Something goes wrong',
                })
            }
        } catch (error) {
            console.error('Error adding checklist:', error);
        }


    }


    return (
        <>
            <NavBar onBack={back}>Create a checklist</NavBar>
            <div className={styles.container}>
                <Space direction="vertical">
                    <Space direction="vertical">
                        <div >
                            {Array.from({ length: count }, (_, index) => (
                                <Card key={index} className={styles.item}>

                                    <Space direction="vertical">
                                        <Space >
                                            <span>
                                                Task {index + 1}:
                                            </span>
                                            <Input
                                                onChange={
                                                    (val) => {
                                                        confirmKey(index, val);
                                                    }
                                                }
                                                placeholder="Please enter the task title" />
                                        </Space>
                                        <Space>
                                            <Radio.Group
                                                value={valueList[index]} // Bind the selected value to the valueList state
                                                onChange={(val) => confirmValue(index, val)}
                                                defaultValue={2}>
                                                Must be &nbsp;&nbsp;
                                                <Radio
                                                    value='1'
                                                > Open</Radio> &nbsp;
                                                <Radio value='2'
                                                > Close</Radio>
                                            </Radio.Group>
                                        </Space>
                                    </Space>

                                </Card>
                            ))}
                        </div>

                    </Space>
                    <Space>
                        <Button color="danger" className={styles.btn}>
                            Back
                        </Button>

                        <Button
                            onClick={() => {
                                submit()
                            }}
                            shape="outline">
                            Create
                        </Button>
                    </Space>
                </Space>

            </div>


        </>

    )
}