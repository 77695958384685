
import ReactEcharts from 'echarts-for-react';
import TopNav from '../../components/topNav/TopNav';
import { List, ProgressBar, Selector } from 'antd-mobile';
import { useEffect, useState } from 'react';
import TopbarComponent from '../../components/topbar/TopbarComponent';
import api from '../../api';
import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom';
export default function StatisticsForChecklist() {

    const navigate = useNavigate();

    const [date, setDate] = useState('')
    const [progressPercentage, setProgressPercentage] = useState('')
    const [topThreeStrings, setTopThreeStrings] = useState('')
    const [numberList, setNumberList] = useState('')
    const [checklistCount, setChecklistCount] = useState(0)
    const [topThree, setTopThree] = useState([])
    const [topThreeList, setTopThreeList] = useState([])
    const [proportionData, setProportionData] = useState([])
    const [proportionNames, setProportionNames] = useState([])
    useEffect(() => {
        const currentDate = new Date();

        // 获取当前年份
        const currentYear = currentDate.getFullYear();

        // 获取当前月份（注意：月份从0开始，需要加1）
        const currentMonth = currentDate.getMonth() + 1;
        const currentD = currentDate.getDate();
        setDate(currentYear + "-" + currentMonth + '-' + currentD)

        // 获取当前日期
        const currentDay = currentDate.getDate();

        // 获取当前月份的天数
        const daysInMonth = new Date(currentYear, currentMonth, 0).getDate();

        // 计算当前日期占当前月进度的百分比
        const progressPercentage = (currentDay / daysInMonth) * 100;

        setProgressPercentage(progressPercentage);
    })

    useEffect(async () => {
        const userJson = localStorage.getItem('user');
        const user = JSON.parse(userJson);
        let res1 = await api.getThreeMonthsWastagePrice(user.userId);
        const obj = res1.data.obj;
        setNumberList(obj.numberList);
        const difference = obj.compareMoneyDifference.toFixed(2);
        setTopThree(obj.topThree);
        setTopThreeList(obj.topThreeList);
        console.log(res1);
        let res = await api.getStatistics(user.userId);
        const map = res.data.obj;
        setChecklistCount(map.count)
        setNumberList(map.topThreeIntegers);
        setTopThreeStrings(map.topThreeStrings);
        setProportionData(map.proportion);
        console.log(proportionData);
        setProportionNames(map.proportionNames);
        console.log(res);
    }, [])

    const getOption = () => {
        return {
            tooltip: {},
            xAxis: {
                type: 'category',
                data: topThreeStrings,
                name: 'Type'
            },
            yAxis: {
                type: 'value',
                name: 'Times'
            },
            series: [
                {
                    name: 'Type',
                    type: 'bar',
                    data: numberList
                }
            ]
        };
    };



    const getOption2 = () => {
        return {
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
                orient: 'vertical',
                left: 'left',
                data: proportionData
            },
            series: [
                {
                    name: 'Fruit',
                    type: 'pie',
                    radius: '50%',
                    data: proportionData,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
    };

    return (
        <>
            <TopNav />
            <div style={{ textAlign: 'center', marginTop: '0.1rem', marginBottom: '0.2rem' }}>
                <Selector
                    onChange={(val) => {
                        const firstEle = val[0];
                        console.log(firstEle);
                        if (firstEle == 1) {
                            navigate("/statistics")
                        } else {
                            navigate("/statisticsForChecklist")
                        }
                    }}
                    style={{
                        '--border-radius': '100px',
                        '--border': 'solid transparent 1px',
                        '--checked-border': 'solid var(--adm-color-primary) 1px',
                        '--padding': '8px 24px',
                    }}
                    showCheckMark={false}
                    options={[
                        {
                            label: 'Food Statistics',
                            value: '1',
                        },
                        {
                            label: 'Checklist Statistics',
                            value: '2',
                        },
                    ]}
                    defaultValue={['2']}
                />
            </div>

            <List>
                <List.Item>
                    <div style={{
                        color: '#01AC71',
                        marginLeft: '0.3rem',
                        fontSize: '2vh',
                        fontWeight: 'bold',
                        marginBottom: '1vh'
                    }}> {date}</div>
                    <div>
                        <ProgressBar percent={progressPercentage} />
                    </div>
                </List.Item>
                <List.Item>
                    Checklist total count of this month: {checklistCount}
                </List.Item>

            </List>
            <div style={{
                color: '#01AC71',
                paddingLeft: '0.8rem',
                fontSize: '1.5vh',
                fontWeight: 'bold',
                marginTop: '0.2rem'
            }}>
                Top three checklists used the most in this month
            </div>
            <ReactEcharts
                className={styles.chartContainer}
                option={getOption()}
                style={{ height: '4.5rem' }}
            />

            <div style={{
                color: '#01AC71',
                paddingLeft: '0.4rem',
                fontSize: '1.5vh',
                fontWeight: 'bold',
                marginBottom: '0.4rem'
            }}>
                The monthly percentage of checked and unchecked tasks
            </div>
            <ReactEcharts
                option={getOption2()}
                style={{ height: '3.5rem', width: '100%' }}
            />
            <TopbarComponent />
        </>

    );
}
