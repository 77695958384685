import { Image, ResultPage } from "antd-mobile";
import TopNav from "../../components/topNav/TopNav";
import logo from '../../assets/photos/instant.png'
import { useEffect } from "react";

export default function () {

    useEffect(() => {

    })
    return (
        <>
            <TopNav />

            <ResultPage
                icon={
                    <Image src={logo}
                        idth={100}
                        height={100}
                        fit='cover'
                        style={{ borderRadius: 4 }}
                    />}
                status='success'
                title='Operation Success'
                description='Thank you for adding your fresh food, you can check it in your inventory and get alert in home page'

            />
        </>

    )
}