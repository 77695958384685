import { ErrorBlock, NavBar } from "antd-mobile";
import { useNavigate } from "react-router-dom";


export default function ErrorPage() {
    const navigate = useNavigate();

    const back = () => {
        navigate('/home')
    }
    return (
        <>
            <NavBar back='Back' onBack={back} />
            <ErrorBlock status="default" fullPage >

            </ErrorBlock>
        </>
    )
}