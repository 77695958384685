import { Button, CalendarPicker, List, Input, Form, Toast } from 'antd-mobile'
import TopNav from '../../components/topNav/TopNav'
import { useEffect, useState } from 'react'
import React, { Component } from 'react'
import styles from './styles.module.css'
import { CapsuleTab, CapsuleTabs } from 'antd-mobile/es/components/capsule-tabs/capsule-tabs'
import api from '../../api'
import { useNavigate } from 'react-router-dom'
export default function AddWastage() {

    const navigate = useNavigate();
    const [visibleDate, setVisibleDate] = useState(false)
    const [visibleDate2, setVisibleDate2] = useState(false)
    const [userId, setUserId] = useState('')
    const [foodCategory, setFoodCategory] = useState('')
    const [name, setName] = useState('')
    const [brand, setBrand] = useState('')
    const [weight, setWeight] = useState('')
    const [unit, setUnit] = useState('')
    const [price, setPrice] = useState('')
    const [quantity, setQuantity] = useState('')
    const [expiredate, setExpireDate] = useState('2024-06-02')
    const [reason, setReason] = useState('');
    const singleDate = new Date('2024-06-02')

    useEffect(() => {
        const userJson = localStorage.getItem('user');
        const user = JSON.parse(userJson);
        if (user == null || user == '') {
            navigate("/login")
        }
    }, [])

    const submit = async () => {

        const userJson = localStorage.getItem('user');
        const user = JSON.parse(userJson);
        console.log(user.userId);
        if (user != null) {
            setUserId(user.userId)
        } 

        if (name === '' || price === '' || unit === ''
            || weight === '' || quantity === '' || brand === ''
            || foodCategory === '' || reason === '') {
            Toast.show({
                icon: 'fail',
                content: 'Please fill all the blanks',
            })
            return;
        }

        if (Number.isInteger(Number(quantity)) == false) {
            Toast.show({
                icon: 'fail',
                content: 'Quantity must be number',
            })
            return;
        }

        if (!isDouble(weight)) {
            Toast.show({
                icon: 'fail',
                content: 'Weight must be number',
            })
            return;
        }

        if (!isDouble(price)) {
            Toast.show({
                icon: 'fail',
                content: 'price must be number',
            })
            return;
        }

        let res = await api.addWastage({
            foodName: name,
            brand: brand,
            quantity: quantity,
            weight: weight,
            unit: unit,
            price: price,
            foodCategory: foodCategory,
            expireDate: expiredate,
            userId: user.userId,
            reason: reason
        });
        if (res.data.code == 200) {
            navigate("/resultPage")
        }
    }

    const isDouble = (value) => {
        return !isNaN(value) && parseFloat(value) === Number(value);
    }

    return (
        <div className={styles.container}>
            <TopNav />
            <div className={styles.title}>
                <span>
                    Add Wastage <Button onClick={() => { submit() }} shape='rounded' color='primary' className={styles.btn}>Submit</Button>
                </span>
            </div>
            <div >
                <span className={styles.subtitle}>Select food type</span>
                <CapsuleTabs className={styles.tab} defaultActiveKey='Vegetables' onChange={(key) => { setFoodCategory(key) }}>
                    <CapsuleTabs.Tab title='Vegetables' key='vegetables' className={styles.tab}>
                        Type: Vegetables
                    </CapsuleTabs.Tab>
                    <CapsuleTabs.Tab title='Fruit' key='fruit' className={styles.tab}>
                        Type: Fruit
                    </CapsuleTabs.Tab>
                    <CapsuleTabs.Tab title='Meat' key='meat'>
                        Type:  Meat
                    </CapsuleTabs.Tab>
                    <CapsuleTabs.Tab title='Diary' key='diary'>
                        Type: Diary
                    </CapsuleTabs.Tab>
                    <CapsuleTabs.Tab title='Oil & Seasoning' key='oil'>
                        Type: Oil & Seasoning
                    </CapsuleTabs.Tab>
                    <CapsuleTabs.Tab title='Drinks' key='drinks'>
                        Type: Drinks
                    </CapsuleTabs.Tab>
                    <CapsuleTabs.Tab title='Eggs' key='eggs'>
                        Type: Eggs
                    </CapsuleTabs.Tab>
                    <CapsuleTabs.Tab title='Grain' key='grain'>
                        Type: Grain
                    </CapsuleTabs.Tab>
                    <CapsuleTabs.Tab title='Other' key='other'>
                        Type: Other
                    </CapsuleTabs.Tab>
                </CapsuleTabs>
            </div>

            <div>
                <Form layout='vertical' className={styles.form}>
                    <Form.Item label='Food Name' name='foodName'>
                        <Input placeholder='Please input food name' clearable
                            onChange={(val) => { setName(val) }} />
                    </Form.Item>
                    <Form.Item label='Food Brand' name='brand'>
                        <Input placeholder='Please input food brand'
                            onChange={(val) => { setBrand(val) }} />
                    </Form.Item>
                    <Form.Item label='Quantity' name='quantity'>
                        <Input placeholder='Please input quantity (number)'
                            onChange={(val) => { setQuantity(val) }} />
                    </Form.Item>
                    <Form.Item label='Weight' name='weight'>
                        <Input placeholder='Please input weight (number)'
                            onChange={(val) => { setWeight(val) }} />
                    </Form.Item>
                    <Form.Item label='Unit' name='unit'>
                        <Input placeholder='Please input unit (e.g. g / Kg)'
                            onChange={(val) => { setUnit(val) }} />
                    </Form.Item>
                    <Form.Item label='Total Price' name='price'>
                        <Input placeholder='Please input price'
                            onChange={(val) => { setPrice(val) }} />
                    </Form.Item>
                    <Form.Item label='Reason' name='reason'>
                        <Input placeholder='Please enter the reason (e.g., OUT OF DATE)'
                            onChange={(val) => { setReason(val) }} />
                    </Form.Item>
                </Form>
            </div>


            <div className={styles.datepick}>
                <List>
                    <List.Item
                        onClick={() => {
                            setVisibleDate2(true)
                        }}
                    >
                        Select expiration date
                        <CalendarPicker
                            visible={visibleDate2}
                            selectionMode='single'
                            defaultValue={singleDate}
                            onClose={() => setVisibleDate2(false)}
                            onMaskClick={() => setVisibleDate2(false)}
                            onChange={(val) => { setExpireDate(val) }}
                        />
                    </List.Item>
                </List>
            </div>
            <div>

            </div>
        </div>
    )
}