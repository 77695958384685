import TopNav from "../../components/topNav/TopNav";
import Dropdown from "antd-mobile/es/components/dropdown/dropdown";
import { Radio, Space, List, Dialog, Toast, Empty } from "antd-mobile";
import api from "../../api";
import styles from './styles.module.css'
import { useEffect, useState } from "react";
import vegetable from '../../assets/food-icons/vegetables.png'
import meat from '../../assets/food-icons/meat.png'
import fruit from '../../assets/food-icons/fruit.png'
import grain from '../../assets/food-icons/grain.png'
import milk from '../../assets/food-icons/milk.png'
import oil from '../../assets/food-icons/oil.png'
import others from '../../assets/food-icons/others.png'
import eggs from '../../assets/food-icons/eggs.png'
import drinks from '../../assets/food-icons/drinks.png'

export default function SearchResult() {

  const [foodList, setFoodList] = useState();
  const [userId, setUserId] = useState();
  const [count, setCount] = useState();
  const [search, setSearch] = useState('');

  useEffect(() => {
    const userJson = localStorage.getItem('user');
    const user = JSON.parse(userJson);
    console.log(user.userId);
    setUserId(user.userId)
    const search = localStorage.getItem('search');
    setSearch(search);
  }, [])

  useEffect(async () => {
    if (userId != undefined && search != undefined) {
      let res = await api.search(userId, search);
      setFoodList(res.data.obj)
    }
  }, [userId])



  return (
    <div className={styles.container}>
      <div>
        <TopNav />
      </div>
      {/* main */}
      <div>
        <div>
          <List className={styles.itemlist}>
            {
              foodList && foodList.length > 0 ? (
                foodList.map(food => {
                  let iconSrc;
                  if (food.foodCategory === 'vegetables') {
                    iconSrc = vegetable;
                  } else if (food.foodCategory === 'meat') {
                    iconSrc = meat;
                  } else if (food.foodCategory === 'egg') {
                    iconSrc = eggs;
                  } else if (food.foodCategory === 'grain') {
                    iconSrc = grain;
                  } else if (food.foodCategory === 'fruit') {
                    iconSrc = fruit;
                  } else if (food.foodCategory === "dairy") {
                    iconSrc = milk;
                  } else if (food.foodCategory === 'oil') {
                    iconSrc = oil;
                  } else if (food.foodCategory === 'drinks') {
                    iconSrc = drinks;
                  } else {
                    iconSrc = others
                  }
                  return (

                    <List.Item
                      onClick={
                        async () => {
                          const result = await Dialog.confirm({
                            content: 'Tick this item',
                            confirmText: 'Yes',
                            cancelText: 'Cancel'
                          })
                          if (result) {
                            let res = await api.tickItem(alert.alertId);
                            Toast.show({
                              icon: 'loading',
                              duration: 2000,
                            })
                            let res2 = await api.getAlertByUser(userId);

                          }
                        }
                      }
                      className={styles.item}
                      style={{
                        // backgroundColor: `${backgroundColor}`
                      }}
                      Exp description={`Expiration date: ${food.expireDate}`}
                      prefix={<img src={iconSrc} style={{
                        width: '1rem',
                        marginLeft: '0.3rem',
                        marginRight: '0.5rem'
                      }} />}
                    >
                      <div className={styles.itemName}>
                        Name: {food.foodName}
                      </div>
                      <div>
                        Bought date: {food.purchaseDate}
                      </div>
                      <div>
                        Location: {food.storageLocation}
                      </div>
                    </List.Item>
                  )
                })
              ) : <div>
                <Empty
                  style={{ padding: '64px 0' }}
                  imageStyle={{ width: 128 }}
                  description='No foods yet'
                />
              </div>
            }

          </List>

        </div>

      </div>
    </div>
  )
}