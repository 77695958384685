
import ReactEcharts from 'echarts-for-react';
import TopNav from '../../components/topNav/TopNav';
import { List, ProgressBar, Selector, Popover, Button } from 'antd-mobile';
import { useEffect, useState } from 'react';
import TopbarComponent from '../../components/topbar/TopbarComponent';
import api from '../../api';
import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom';
import { QuestionCircleOutline } from 'antd-mobile-icons';
export default function Statistics() {

    const navigate = useNavigate();

    const [date, setDate] = useState('')
    const [progressPercentage, setProgressPercentage] = useState('')
    const [priceList, setPriceList] = useState('')
    const [numberList, setNumberList] = useState('')
    const [inventoryCount, setInventoryCount] = useState(0)
    const [wastageSize, setWastageSize] = useState(0)
    const [magnitude, setMagnitude] = useState('')
    const [difference, setDifference] = useState('')
    const [currentAmount, setCurrentAmount] = useState(0)
    const [topThree, setTopThree] = useState([])
    const [topThreeList, setTopThreeList] = useState([])
    useEffect(() => {
        const currentDate = new Date();

        // 获取当前年份
        const currentYear = currentDate.getFullYear();

        // 获取当前月份（注意：月份从0开始，需要加1）
        const currentMonth = currentDate.getMonth() + 1;
        const currentD = currentDate.getDate();
        setDate(currentYear + "-" + currentMonth + '-' + currentD)

        // 获取当前日期
        const currentDay = currentDate.getDate();

        // 获取当前月份的天数
        const daysInMonth = new Date(currentYear, currentMonth, 0).getDate();

        // 计算当前日期占当前月进度的百分比
        const progressPercentage = (currentDay / daysInMonth) * 100;

        setProgressPercentage(progressPercentage);
    })

    useEffect(async () => {
        const userJson = localStorage.getItem('user');
        const user = JSON.parse(userJson);
        let res = await api.getThreeMonthsWastagePrice(user.userId);
        const obj = res.data.obj;
        setPriceList(obj.priceList);
        setNumberList(obj.numberList);
        setInventoryCount(obj.inventoryCount);
        setWastageSize(obj.wasteSize);
        setMagnitude(obj.magnitude);
        const difference = obj.compareMoneyDifference.toFixed(2);
        setDifference(difference);
        setCurrentAmount(obj.currentAmount);
        setTopThree(obj.topThree);
        setTopThreeList(obj.topThreeList);
        console.log(res);
    }, [])

    const getOption = () => {
        return {
            tooltip: {},
            xAxis: {
                type: 'category',
                data: numberList,
                name: 'Date'
            },
            yAxis: {
                type: 'value',
                name: 'Price'
            },
            series: [
                {
                    name: 'Price',
                    type: 'line',
                    data: priceList
                }
            ]
        };
    };

    const getOption2 = () => {
        return {
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
                orient: 'vertical',
                left: 'left',
                data: topThreeList
            },
            series: [
                {
                    name: 'Fruit',
                    type: 'pie',
                    radius: '50%',
                    data: topThree,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
    };

    return (
        <>
            <TopNav />
            <div style={{ textAlign: 'center', marginTop: '0.1rem', marginBottom: '0.2rem' }}>
                <Selector
                    onChange={(val) => {
                        const firstEle = val[0];
                        console.log(firstEle);
                        if (firstEle == 1) {
                            navigate("/statistics")
                        } else {
                            navigate("/statisticsForChecklist")
                        }
                    }}
                    style={{
                        '--border-radius': '100px',
                        '--border': 'solid transparent 1px',
                        '--checked-border': 'solid var(--adm-color-primary) 1px',
                        '--padding': '8px 24px',
                    }}
                    showCheckMark={false}
                    options={[
                        {
                            label: 'Food Statistics',
                            value: '1',
                        },
                        {
                            label: 'Checklist Statistics',
                            value: '2',
                        },
                    ]}
                    defaultValue={['1']}
                />
            </div>
            <List>
                <List.Item>
                    <div style={{
                        color: '#01AC71',
                        marginLeft: '0.3rem',
                        fontSize: '2vh',
                        fontWeight: 'bold',
                        marginBottom: '1vh'
                    }}> {date}</div>
                    <div>
                        <ProgressBar percent={progressPercentage} />
                    </div>
                </List.Item>
                <List.Item>
                    The number of added food items: {inventoryCount}
                </List.Item>
                <List.Item>
                    The quantity wasted: {wastageSize}
                </List.Item>
                <List.Item>
                    The amount wasted: {currentAmount}
                </List.Item>
                <List.Item>
                    Compared to last month:
                    <span style={{
                        color: magnitude === 'decrease' ? 'green' : 'red'
                    }}>
                        {difference}
                    </span>
                    &nbsp; &nbsp; &nbsp;
                    <Popover
                        content='If the number is minus and green, it means you have reduced the amount of waste compared to last month, and if the number is positive and red, it means you have increased the amount of waste compared to last month.'
                        trigger='click'
                        placement='top'
                    >

                        <Button color='primary' size='mini' >
                            <QuestionCircleOutline
                                fontSize={20} />
                        </Button>

                    </Popover>
                </List.Item>
            </List>
            <div style={{
                color: '#01AC71',
                paddingLeft: '0.8rem',
                fontSize: '1.5vh',
                fontWeight: 'bold',
                marginTop: '0.2rem'
            }}>
                Money wasted on food in the past three months.
            </div>
            <ReactEcharts
                className={styles.chartContainer}
                option={getOption()}
                style={{ height: '4.5rem' }}
            />

            <div style={{
                color: '#01AC71',
                paddingLeft: '0.8rem',
                fontSize: '1.5vh',
                fontWeight: 'bold',
                marginBottom: '0.2rem'
            }}>
                The top three categories with the most food wastage
            </div>
            <ReactEcharts
                option={getOption2()}
                style={{ height: '3.5rem', width: '100%' }}
            />
            <TopbarComponent />
        </>

    );
}
