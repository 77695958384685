import React, { useEffect } from 'react'
import { Input, Form, Button, Toast, Divider, Space } from 'antd-mobile'
import { EyeInvisibleOutline, EyeOutline } from 'antd-mobile-icons'
import { useState } from 'react'
import styles from './style.module.css'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/photos/instant.png'
import api from '../../api'

export default function Register() {

    const navigate = useNavigate();
    const [visible, setVisible] = useState(false)
    const [visible2, setVisible2] = useState(false)
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [house, setHouse] = useState('')
    // let obj = { username: username, password: password };

    // useEffect(() => {
    //     console.log('username=>', username);
    //     console.log('password=>', password);

    // }, [username, password])



    async function submit() {
        if (username === '' ||
            password === '' ||
            email === '' ||
            house === '' ||
            password2 == '') {
            Toast.show({
                icon: 'fail',
                content: 'Register Failed. At least one is blank.'
            })
            return;
        }

        if (username.length < 3 || username.length > 20) {
            Toast.show({
                icon: 'fail',
                content: 'The length of username must be between 3 and 20.'
            })
            return;
        }

        if (password.length < 6 || password.length > 20) {
            Toast.show({
                icon: 'fail',
                content: 'The length of password must be between 6 and 20.'
            })
            return;
        }

        if (!email.includes('@')) {
            Toast.show({
                icon: 'fail',
                content: 'Wrong email format.'
            })
            return;
        }

        if (password != password2) {
            Toast.show({
                icon: 'fail',
                content: 'The second password does not match the first one.'
            })
            return;
        }

        let res = await api.register({
            userName: username,
            password: password,
            email: email,
            houseName: house,
        })

        if (res.data.code == 200) {
            Toast.show({
                icon: 'success',
                content: 'Register successully',
            })
            navigate('/login')
        } else {
            Toast.show({
                icon: 'fail',
                content: 'Something went wrong.'
            })

        }
        console.log(res);
    }

    // async function getData() {
    //     let res = await api.getUser({ username: username, password: password })
    //     console.log(res);
    //     if (res.data.code !== 200) {
    //         Toast.show({
    //             icon: 'fail',
    //             content: 'Wrong username or password'
    //         })
    //         return;
    //     }
    //     const user = res.data.obj
    //     console.log('user', user);
    //     user.password = '';
    //     localStorage.setItem('user', JSON.stringify(user))
    //     localStorage.setItem('iflogin', true)
    //     navigate(`/home?justLogin=true`)
    // }

    // function back() {
    //     navigate('/home')
    // }

    return (
        <div className={styles.login}>


            <div className={styles.brand}>
                <img src={logo} className={styles.logo} />
            </div>

            {/* 用户名密码 */}
            <div className={styles.loginbox}>
                <Space direction='vertical'>
                    <span className={styles.tooltip}>
                        Please create your username
                    </span>
                    <div className={styles.password}>
                        <Input placeholder='Username'
                            onChange={Value => { setUsername(Value) }}
                            style={{
                                '--color': 'white',
                                paddingLeft: '0.8rem',
                            }} />
                    </div>
                    <span className={styles.tooltip}>
                        Please input your email
                    </span>
                    <div className={styles.password}>
                        <Input placeholder='Email'
                            onChange={Value => { setEmail(Value) }}
                            style={{
                                '--color': 'white',
                                paddingLeft: '0.8rem',
                            }} />
                    </div>
                    <span className={styles.tooltip}>
                        Please create a name for your house
                    </span>
                    <div className={styles.password}>
                        <Input placeholder='House name'
                            onChange={Value => { setHouse(Value) }}
                            style={{
                                '--color': 'white',
                                paddingLeft: '0.8rem',
                            }} />
                    </div>
                    <div>
                        <span className={styles.tooltip}>
                            Please set your password
                        </span>
                        <div className={styles.password}>
                            <Input
                                onChange={Value => { setPassword(Value) }}
                                className={styles.input}
                                placeholder='Password'
                                type={visible ? 'text' : 'password'}
                                style={{
                                    '--color': 'white',
                                    paddingLeft: '0.8rem',
                                    paddingTop: '0.1rem',
                                    paddingBottom: '0.1rem'
                                }}
                            />
                            <div className={styles.eye}>
                                {!visible ? (
                                    <EyeInvisibleOutline onClick={() => setVisible(true)} />
                                ) : (
                                    <EyeOutline onClick={() => setVisible(false)} />
                                )}
                            </div>
                        </div>
                    </div>
                    <span className={styles.tooltip}>
                        Please confirm your password
                    </span>
                    <div>
                        <div className={styles.password}>
                            <Input
                                onChange={Value => { setPassword2(Value) }}
                                className={styles.input}
                                placeholder='Password'
                                type={visible2 ? 'text' : 'password'}
                                style={{
                                    '--color': 'white',
                                    paddingLeft: '0.8rem',
                                    paddingTop: '0.1rem',
                                    paddingBottom: '0.1rem',
                                    marginBottom: '0.3rem'
                                }}
                            />
                            <div className={styles.eye}>
                                {!visible2 ? (
                                    <EyeInvisibleOutline onClick={() => setVisible2(true)} />
                                ) : (
                                    <EyeOutline onClick={() => setVisible2(false)} />
                                )}
                            </div>
                        </div>
                    </div>
                </Space>


            </div>
            {/* 登录按钮 */}
            <Button block
                color='secondary'
                size='large'
                shape='rounded'
                onClick={submit}
                className={styles.submitbtn} >Register</Button>

            <Button
                color='secondary'
                fill='outline'
                className={styles.createbtn}
                shape='rounded'>

                Go to sign in
            </Button>
        </div>


    )
};

